import { Feature, Position } from "geojson";
import turfUnion from "@turf/union";
import { point } from "@turf/helpers";
import { geojsonToWKT } from "@terraformer/wkt";

import { distance2d } from "./modes";
import { generateId } from "./string";
import { RGB_COLORS } from "../consts/style";
import {
  GEOJSON_TYPES,
  PIPELINE_PREFIX,
  DEFAULT_LINE_ID,
  DEFAULT_LINE_PERIMETER_ID,
  DEFAULT_ML_PLUMBING_COUNTS,
  DEFAULT_PIPLELINES_CLASSiFICATIONS_MAP,
} from "../consts/editor";
import {
  PIPE_VECTOR_TYPE,
  PIPELINE_LENGTHS,
  PIPELINE_MODE_TOOLS,
  PIPELINE_WIDTHS_MAP,
  PIPELINE_TYPES_WIDTHS_MAP,
} from "../consts/pipeline";

import { isNumber } from "./math";
import { getBufferedGeometry } from "../modes/mutate/utils";
import { handleNearestPointOnLine } from "../modes/edit/utils";
import {
  mergeGeometries,
  getPipelineTubeGeometries,
} from "../modes/3drendering/utils";

/**
 *
 *
 * CONSTS
 *
 *
 */

const COLLINEAR_THRESHOLD = 1e-9;
const MAX_INTERSECT_DISTANCE = 200;

type Polygon = Point[];
type Point = [number, number];
type Point3D = [number, number, number];

/**
 *
 *
 * CONSTS
 *
 *
 */

/**
 *
 *
 * 2D Pipeline Utils
 *
 *
 */

export function isFeaturePipeline(feature: any) {
  return (
    feature?.properties?.isPipeline ||
    feature?.properties?.types?.includes(PIPELINE_PREFIX)
  );
}

export function isFeaturePipelineCount(feature: any) {
  return (
    feature?.geometry?.type === GEOJSON_TYPES.Point &&
    DEFAULT_ML_PLUMBING_COUNTS.includes(feature?.properties?.className)
  );
}

export function isFeatureCenterLine(feature: any) {
  return (
    feature?.geometry?.type === GEOJSON_TYPES.LineString &&
    feature?.properties?.className === DEFAULT_LINE_ID
  );
}

export function isFeatureLinePerimeter(feature: any) {
  return (
    (feature?.geometry?.type === GEOJSON_TYPES.LineString ||
      feature?.geometry?.type === GEOJSON_TYPES.MultiLineString) &&
    feature?.properties?.className === DEFAULT_LINE_PERIMETER_ID
  );
}

export function roundFeatureCoords(feature: any, nbOfDecimals: number = 0) {
  const roundCoords = (coords: any) => {
    if (Array.isArray(coords[0])) {
      return coords.map((coord: any) => roundCoords(coord));
    }
    return coords.map((coord: any) => parseFloat(coord.toFixed(nbOfDecimals)));
  };

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: roundCoords(feature.geometry.coordinates),
    },
  };
}

export function getPipelineModeDetails(pipelineMode: string) {
  const isEditMode = pipelineMode === PIPELINE_MODE_TOOLS.EDIT;
  const isEntryMode = pipelineMode === PIPELINE_MODE_TOOLS.EXIT_DRAIN;
  const isPipelineMode = pipelineMode === PIPELINE_MODE_TOOLS.PIPELINE;

  const isDrainMode = [
    PIPELINE_MODE_TOOLS.DRAIN,
    ...DEFAULT_ML_PLUMBING_COUNTS,
  ].includes(pipelineMode);

  const pipelineWidth = 2 * 5;

  return {
    isEditMode,
    isEntryMode,
    isDrainMode,
    pipelineWidth,
    isPipelineMode,
  };
}

export function snapNearestPoint(pipelines: any[], point: any) {
  const pipeline = pipelines.find(
    (p) => p.properties.id === point.properties.parentId
  );
  const pipelineCoords = pipeline.geometry.coordinates;
  const pointCoords = point.geometry.coordinates;

  let snapped = false;
  let snappedPoint = null;

  for (const coord of pipelineCoords) {
    const dist = distance2d(coord[0], coord[1], pointCoords[0], pointCoords[1]);

    if (dist < 30) {
      snapped = true;
      snappedPoint = {
        ...point,
        properties: {
          ...point.properties,
          snapped: true,
          dist,
        },
        geometry: {
          ...point.geometry,
          coordinates: coord,
        },
      };
      break;
    }
  }

  return snapped ? snappedPoint : point;
}

export function getPipelinesNearestPoint(
  pipelines: any[],
  mapCoords: Position
): any {
  let nearest = null;
  let parentId = null;
  let nearestIndex = null;

  const referencePoint = point(mapCoords);

  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i];
    if (!pipeline.properties.isDrainLine) {
      const coordinates = pipeline.geometry.coordinates;

      for (let j = 0; j < coordinates.length - 1; j++) {
        const lineCoords = [coordinates[j], coordinates[j + 1]];
        const line = {
          type: GEOJSON_TYPES.Feature,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: lineCoords,
          },
          properties: {},
        };

        const nearestPoint = handleNearestPointOnLine(
          line,
          referencePoint,
          null,
          10
        );

        if (nearestPoint) {
          const cleanedPoint = roundFeatureCoords({
            ...nearestPoint,
            geometry: {
              type: "Point",
              coordinates: nearestPoint.geometry.coordinates,
            },
            properties: {
              ...nearestPoint.properties,
              snapped: false,
              dist: nearestPoint.properties.dist,
            },
          });

          if (
            !nearest ||
            cleanedPoint.properties.dist < nearest.properties.dist
          ) {
            parentId = pipeline.properties.id;
            nearestIndex = j;
            nearest = cleanedPoint;
          }
        }
      }
    }
  }

  return nearest
    ? snapNearestPoint(pipelines, {
        ...nearest,
        properties: {
          ...(nearest?.properties || {}),
          parentId,
          positionIndexes: [nearestIndex + 1],
        },
      })
    : null;
}

export function getUpdatedPipelines(
  pipelines: any[],
  nearestPoint: any,
  colorMap: any,
  referenceInchesPerPixels: number,
  heightValue: number
) {
  let newPipelines = [];
  const removedFeatures: any[] = [];
  const addedFeatures: any[] = [];

  if (heightValue) {
    const filteredPipelines = pipelines.filter(
      (p: any) => p.properties.isVertical
    );

    let snappedVerticalLine = null;
    let minDistance = Infinity;

    const newPointCoords = [
      nearestPoint.geometry.coordinates[0],
      nearestPoint.geometry.coordinates[1],
      heightValue,
    ];

    for (const pipeline of filteredPipelines) {
      const lineCoords = pipeline.geometry.coordinates;
      const pointCoords = nearestPoint.geometry.coordinates;
      const dist = distance2d(
        lineCoords[0][0],
        lineCoords[0][1],
        pointCoords[0],
        pointCoords[1]
      );
      if (dist < minDistance) {
        const doesPointExist = pipeline.geometry.coordinates.some(
          (coord: any) =>
            Math.round(coord[0]) === Math.round(newPointCoords[0]) &&
            Math.round(coord[1]) === Math.round(newPointCoords[1]) &&
            Math.round(coord[2]) === Math.round(newPointCoords[2])
        );

        if (!doesPointExist) {
          minDistance = dist;
          snappedVerticalLine = pipeline;
        }
      }
    }

    if (snappedVerticalLine && minDistance < 5) {
      newPipelines = pipelines.flatMap((p: any) => {
        if (p?.properties?.id === snappedVerticalLine?.properties?.id) {
          removedFeatures.push(p);
          const line1ID = generateId();
          const line2ID = generateId();

          let line1 = roundFeatureCoords({
            type: GEOJSON_TYPES.Feature,
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [p.geometry.coordinates[0], newPointCoords],
            },
            properties: {
              ...p.properties,
              id: line1ID,
              isVertical: true,
            },
          });

          let line2 = roundFeatureCoords({
            type: GEOJSON_TYPES.Feature,
            geometry: {
              type: GEOJSON_TYPES.LineString,
              coordinates: [newPointCoords, p.geometry.coordinates[1]],
            },
            properties: {
              ...p.properties,
              id: line2ID,
              isVertical: true,
            },
          });

          line1 = pipelineToPolygon(line1, colorMap, referenceInchesPerPixels);
          line2 = pipelineToPolygon(line2, colorMap, referenceInchesPerPixels);

          addedFeatures.push(line1);
          addedFeatures.push(line2);

          return [line1, line2].map((f: any) => roundFeatureCoords(f));
        }
        return [p];
      });

      return {
        newPipelines,
        addedFeatures,
        removedFeatures,
      };
    }

    return {
      addedFeatures,
      removedFeatures,
      newPipelines: pipelines,
    };
  }

  newPipelines = pipelines.flatMap((p: any) => {
    if (p.properties.id === nearestPoint.properties.parentId) {
      const height = p.geometry.coordinates[0][2] || 0;

      removedFeatures.push(p);
      const line1ID = generateId();
      const line2ID = generateId();

      let line1 = roundFeatureCoords({
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: [
            p.geometry.coordinates[0],
            [
              nearestPoint.geometry.coordinates[0],
              nearestPoint.geometry.coordinates[1],
              height,
            ],
          ],
        },
        properties: {
          ...p.properties,
          id: line1ID,
        },
      });

      let line2 = roundFeatureCoords({
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: [
            [
              nearestPoint.geometry.coordinates[0],
              nearestPoint.geometry.coordinates[1],
              height,
            ],
            p.geometry.coordinates[1],
          ],
        },
        properties: {
          ...p.properties,
          id: line2ID,
        },
      });

      line1 = pipelineToPolygon(line1, colorMap, referenceInchesPerPixels);
      line2 = pipelineToPolygon(line2, colorMap, referenceInchesPerPixels);

      addedFeatures.push(line1);
      addedFeatures.push(line2);

      return [line1, line2].map((f: any) => roundFeatureCoords(f));
    }

    return p;
  });

  return {
    newPipelines,
    addedFeatures,
    removedFeatures,
  };
}

export function getNewDrainLine(
  mapCoords: any,
  nearestCoordinates: any,
  colorMap: any,
  referenceInchesPerPixels: number,
  props: any = {}
) {
  const newID = generateId();

  const newLineFeature = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: [nearestCoordinates, mapCoords],
    },
    properties: {
      ...props,
      id: newID,
      isDrainLine: true,
      types: ["line"],
    },
  };
  return pipelineToPolygon(newLineFeature, colorMap, referenceInchesPerPixels);
}

/**
 *
 * code started from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
 * and added the mitter types and the geometry conversion
 */

function scalarMult(s: any, v: any) {
  return [s * v[0], s * v[1]];
}

function intersectSegments(a: any, b: any) {
  var p = a[0];
  var r = [a[1][0] - a[0][0], a[1][1] - a[0][1]];
  var q = b[0];
  var s = [b[1][0] - b[0][0], b[1][1] - b[0][1]];

  var cross = r[0] * s[1] - s[0] * r[1];
  var qmp = [q[0] - p[0], q[1] - p[1]];
  var numerator = qmp[0] * s[1] - s[0] * qmp[1];
  var t = numerator / cross;
  var intersection = [p[0] + scalarMult(t, r)[0], p[1] + scalarMult(t, r)[1]];
  return intersection;
}

function isParallel(a: any, b: any) {
  var r = [a[1][0] - a[0][0], a[1][1] - a[0][1]];
  var s = [b[1][0] - b[0][0], b[1][1] - b[0][1]];
  return r[0] * s[1] - s[0] * r[1] === 0;
}

function intersection(a: any, b: any) {
  if (isParallel(a, b)) return false;
  return intersectSegments(a, b);
}

// from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
function processSegment(point1: any, point2: any, offset: number) {
  var L = Math.sqrt(
    (point1[0] - point2[0]) * (point1[0] - point2[0]) +
      (point1[1] - point2[1]) * (point1[1] - point2[1])
  );

  var out1x = point1[0] + (offset * (point2[1] - point1[1])) / L;
  var out1y = point1[1] + (offset * (point1[0] - point2[0])) / L;

  var out2x = point2[0] + (offset * (point2[1] - point1[1])) / L;
  var out2y = point2[1] + (offset * (point1[0] - point2[0])) / L;

  return [
    [out1x, out1y],
    [out2x, out2y],
  ];
}

function calculateInnerAngle(p1: Point, p2: Point, intersectionPoint: Point) {
  const vector1 = [p1[0] - intersectionPoint[0], p1[1] - intersectionPoint[1]];
  const vector2 = [p2[0] - intersectionPoint[0], p2[1] - intersectionPoint[1]];

  const dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];
  const magnitude1 = Math.sqrt(
    vector1[0] * vector1[0] + vector1[1] * vector1[1]
  );
  const magnitude2 = Math.sqrt(
    vector2[0] * vector2[0] + vector2[1] * vector2[1]
  );

  if (magnitude1 === 0 || magnitude2 === 0) {
    return null;
  }

  const cosAngle = dotProduct / (magnitude1 * magnitude2);

  const angleInDegrees = Math.acos(cosAngle) * (180 / Math.PI);
  return angleInDegrees;
}

function areCollinear(p1: Point, p2: Point, p3: any) {
  const crossProduct =
    (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);

  return Math.abs(crossProduct) < COLLINEAR_THRESHOLD;
}

function extendLine(p1: Point, p2: Point, distance: number) {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];
  const lineLength = Math.sqrt(dx ** 2 + dy ** 2);

  const ux = dx / lineLength;
  const uy = dy / lineLength;

  const newX = p2[0] + ux * distance;
  const newY = p2[1] + uy * distance;

  return [newX, newY];
}

// from https://github.com/Turfjs/turf/blob/master/packages/turf-line-offset/index.js
function lineOffset(coords: any, distance: number) {
  const segments: any = [];
  const finalCoords: any = [];

  coords.forEach(function (currentCoords: any, index: number) {
    if (index !== coords.length - 1) {
      const segment: any = processSegment(
        currentCoords,
        coords[index + 1],
        distance
      );
      segments.push(segment);

      if (coords.length === 2) {
        finalCoords.push(segment[0]);
        finalCoords.push(segment[1]);
      } else {
        if (index > 0) {
          const previousSegment = segments[index - 1];
          const intersects: any = intersection(segment, previousSegment);

          if (intersects !== false) {
            const distanceToIntersect = Math.sqrt(
              (currentCoords[0] - intersects[0]) *
                (currentCoords[0] - intersects[0]) +
                (currentCoords[1] - intersects[1]) *
                  (currentCoords[1] - intersects[1])
            );
            const angle = calculateInnerAngle(
              previousSegment[0],
              segment[1],
              intersects
            );

            const angle2 = calculateInnerAngle(
              previousSegment[0],
              segment[1],
              currentCoords
            );

            if (!!angle && !!distanceToIntersect) {
              const cond1 = distanceToIntersect < MAX_INTERSECT_DISTANCE;
              const cond2 =
                distanceToIntersect >= MAX_INTERSECT_DISTANCE && angle > angle2;
              const cond = cond1 || cond2;

              if (cond) {
                previousSegment[1] = intersects;
                segment[0] = intersects;

                finalCoords.push(previousSegment[0]);

                if (index === coords.length - 2) {
                  finalCoords.push(segment[0]);
                  finalCoords.push(segment[1]);
                }
              } else {
                // apply mitter options

                // extend the previous segment
                previousSegment[1] = extendLine(
                  previousSegment[0],
                  previousSegment[1],
                  Math.abs(distance * 2)
                );
                segment[0] = extendLine(
                  segment[1],
                  segment[0],
                  Math.abs(distance * 2)
                );
                // extend the previous segment

                finalCoords.push(previousSegment[0]);
                finalCoords.push(previousSegment[1]);

                if (index === coords.length - 2) {
                  finalCoords.push(segment[0]);
                  finalCoords.push(segment[1]);
                }
              }
            } else {
              finalCoords.push(previousSegment[0]);
              finalCoords.push(previousSegment[1]);

              if (index === coords.length - 2) {
                finalCoords.push(currentCoords);
              }
            }
          } else {
            if (
              !areCollinear(
                previousSegment[0],
                previousSegment[1],
                currentCoords
              )
            ) {
              finalCoords.push(previousSegment[0]);

              if (index === coords.length - 2) {
                finalCoords.push(segment[0]);
                finalCoords.push(segment[1]);
              }
            }
          }
        }
      }
    }
  });

  return finalCoords;
}
export function pipelineToPolygon(
  lineFeature: any,
  colorMap: any,
  referenceInchesPerPixels: any,
  overrideWidth?: number
): Feature | null {
  let width = 0.5;
  let linePolygon;
  let className = "";
  let featureClass = null;
  let color = RGB_COLORS.PIPELINE;

  if (colorMap && lineFeature?.properties?.className in colorMap) {
    featureClass = colorMap[lineFeature?.properties?.className];

    width = featureClass?.size || 0.5;
    color = featureClass.colorStyle.color;
    className = lineFeature.properties.className;
  } else {
    const defaultClassName =
      PIPELINE_WIDTHS_MAP[lineFeature?.properties?.pipelineWidth ?? 0.5];

    if (defaultClassName in DEFAULT_PIPLELINES_CLASSiFICATIONS_MAP) {
      featureClass = DEFAULT_PIPLELINES_CLASSiFICATIONS_MAP[defaultClassName];

      width = lineFeature.properties.pipelineWidth;
      color = featureClass.colorStyle.color;
      className = defaultClassName;
    }
  }

  width =
    overrideWidth ?? (Math.round(width / referenceInchesPerPixels / 2) || 5);

  const linePoints: Point[] | Point3D[] =
    lineFeature.geometry.type === GEOJSON_TYPES.LineString
      ? lineFeature.geometry.coordinates
      : lineFeature.geometry.type === GEOJSON_TYPES.MultiLineString
      ? lineFeature.geometry.coordinates[0]
      : [];

  if (linePoints.length < 2) {
    return null;
  }

  const z1 = linePoints[0][2] || 0;
  const z2 = linePoints[1][2] || 0;
  const isVertical =
    z1 !== z2 &&
    linePoints[0][0] === linePoints[1][0] &&
    linePoints[0][1] === linePoints[1][1];

  const leftLinePoints = lineOffset(linePoints, width);
  const rightLinePoints: Point[] = lineOffset(linePoints, -width);

  rightLinePoints.reverse();

  const polygon: Polygon = leftLinePoints.concat(rightLinePoints);
  polygon.push(leftLinePoints[0]);

  linePolygon = {
    type: GEOJSON_TYPES.Feature,
    geometry: {
      type: GEOJSON_TYPES.Polygon,
      coordinates: [polygon],
    },
    properties: {
      color,
      opacity: 20,
      className,
      isVertical,
      borderColor: color,
      pipelineWidth: width,
      id: lineFeature.properties.id,
    },
  };

  if (isVertical) {
    const point = linePoints[0];
    const pointFeature = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.Point,
        coordinates: point,
      },
      properties: {},
    };

    const buffered = getBufferedGeometry(pointFeature, 2);

    linePolygon = {
      type: GEOJSON_TYPES.Feature,
      geometry: {
        type: GEOJSON_TYPES.Polygon,
        coordinates: buffered.geometry.coordinates,
      },
      properties: {
        color,
        className,
        opacity: 20,
        isVertical,
        borderColor: color,
        pipelineWidth: width,
        id: lineFeature.properties.id,
      },
    };
  }

  return {
    ...lineFeature,
    properties: {
      ...lineFeature.properties,
      linePolygon,
      className,
      isVertical,
    },
  };
}

/**
 *
 *
 * 3D Pipeline Utils
 *
 *
 */

export async function generate3dPipeline(
  pipelines: any[],
  referenceInchesPerPixels: number,
  offset?: number
) {
  if (!pipelines || pipelines.length === 0) return null;
  const tubes: any[] = [];

  const zOffset = isNumber(offset) ? offset : 3 / referenceInchesPerPixels;

  for (const pipeline of pipelines) {
    const width = getPipelineWidth(
      pipeline?.properties?.pipelineWidth,
      referenceInchesPerPixels
    );

    const pipelineGeometry = await getPipelineTubeGeometries(
      pipeline,
      width,
      zOffset
    );
    tubes.push(pipelineGeometry);
  }
  const finalGeometry = mergeGeometries(tubes);
  return finalGeometry;
}

/**
 *
 *
 * API Pipeline Utils
 *
 *
 */

function getApiPipeWidth(pipeType: string, referenceInchesPerPixels: number) {
  const pipeWidth = PIPELINE_TYPES_WIDTHS_MAP[pipeType];
  return pipeWidth / referenceInchesPerPixels;
}

export function convertApiToGeometry(
  { floors }: any,
  referenceInchesPerPixels: number
) {
  return floors?.[0]?.pipes?.map(
    (pipe: { category: string; position: any[] }) => {
      const width = getApiPipeWidth(pipe?.category, referenceInchesPerPixels);

      const lineFeature: any = {
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: [
            [pipe?.position[0], pipe?.position[1], pipe?.position[2]],
            [pipe?.position[3], pipe?.position[4], pipe?.position[5]],
          ],
        },
        properties: {
          isApiPipe: true,
          id: generateId(),
          linePolygon: null,
          pipelineWidth: width,
        },
      };
      return pipelineToPolygon(
        lineFeature,
        new Map(),
        referenceInchesPerPixels,
        width
      );
    }
  );
}

export function convertCenterlineToWkt(features: any[]) {
  let coords: any[] = [];

  const centerlinePolygons = features
    .filter((f) => f?.properties?.types?.includes("centerline"))
    ?.map((fe) => pipelineToPolygon(fe, new Map(), 1, 20));

  centerlinePolygons.forEach((fe) => {
    coords = [...coords, fe?.properties?.linePolygon?.geometry?.coordinates];
  });

  const centerlineMultyPolygon = {
    type: GEOJSON_TYPES.MultiPolygon,
    coordinates: coords,
  };

  return geojsonToWKT(centerlineMultyPolygon);
}

export function convertFixturesToDrains(features: any[]) {
  // TODO: add height
  return features
    ?.filter((f) =>
      DEFAULT_ML_PLUMBING_COUNTS?.includes(f?.properties?.className)
    )
    ?.map((feature, index) => {
      const isToilet = feature.properties.types.includes("Toilet");
      return {
        id: index + 1 || feature?.properties?.id,
        type: isToilet ? 0 : 1,
        position: [
          feature.geometry.coordinates[0],
          feature.geometry.coordinates[1],
        ],
      };
    });
}

export function convertVectorsToDrains(features: any[]) {
  const exitDrains = features
    ?.filter((f) => f?.properties?.types?.includes(PIPE_VECTOR_TYPE))
    ?.map((feature, idx) => {
      const lineCoordinates = feature?.geometry.coordinates?.[0];

      const position_start = lineCoordinates?.[0];
      const position_end = lineCoordinates?.[1];

      return {
        id: idx,
        type: 2,
        ...(position_start
          ? {
              position: position_start,
              main_pipe_dir: [
                position_end[0] - position_start[0],
                position_end[1] - position_start[1],
              ],
            }
          : { position_start, position_end }),
      };
    });

  return exitDrains;
}

export function lineFeatureToSegments(
  line: any,
  asFeatures = false,
  properties = {}
) {
  const segments = [];

  const lineCoordinates: any[] =
    line.geometry.type === GEOJSON_TYPES.LineString
      ? line.geometry.coordinates
      : line.geometry.type === GEOJSON_TYPES.MultiLineString ||
        line.geometry.type === GEOJSON_TYPES.Polygon
      ? line.geometry.coordinates[0]
      : [];

  for (let i = 0; i < lineCoordinates.length - 1; i++) {
    segments.push([
      lineCoordinates[i][0],
      lineCoordinates[i][1],
      lineCoordinates[i][2] || 0,
      lineCoordinates[i + 1][0],
      lineCoordinates[i + 1][1],
      lineCoordinates[i + 1][2] || 0,
    ]);
  }

  return asFeatures
    ? segments.map((s) => ({
        type: GEOJSON_TYPES.Feature,
        geometry: {
          type: GEOJSON_TYPES.LineString,
          coordinates: [
            [s[0], s[1], s[2]],
            [s[3], s[4], s[5]],
          ],
        },
        properties: {
          ...properties,
          id: generateId(),
        },
      }))
    : segments;
}
export function convertToPipes(pipelines: any[]) {
  const lines: any[] = [];

  for (const pipeline of pipelines) {
    const pipelineSegments = lineFeatureToSegments(pipeline);

    for (const segment of pipelineSegments) {
      lines.push({
        category: "pvc3",
        position: segment,
        id: lines.length + 1,
        // category: pipeline.properties.category,
      });
    }
  }

  return lines;
}

export function calculatePipelinePayload(
  vectorFeatures: any[],
  features: any[],
  pipelines: any[],
  page_id: string,
  pixelsPerInch: number
) {
  const fixtures = convertFixturesToDrains(features) || [];
  const exitDrains = convertVectorsToDrains(vectorFeatures) || [];
  const wallPolygonWkt = convertCenterlineToWkt(features) || "";
  const predefinedPipes = convertToPipes(pipelines) || [];

  const drains = [...exitDrains, ...fixtures]?.map((drain, index) => ({
    ...drain,
    id: index,
  }));

  if (exitDrains.length === 0) return null;

  const payload: any = {
    floors: [
      {
        id: 0,
        drains,
        page_id,
        elevation: [0, 0],
        wall_polygon: wallPolygonWkt,
        predefined_pipes: predefinedPipes,
      },
    ],
    risers: [],
    version: "1.00.00",
    scale: pixelsPerInch,
  };

  return payload;
}

export function getPipelineWidth(
  width: number,
  referenceInchesPerPixels: number
) {
  return Math.round(width / referenceInchesPerPixels / 2) ?? 10;
}

export function getPipelineWidthReal(
  width: number,
  referenceInchesPerPixels: number
) {
  return width * referenceInchesPerPixels * 2;
}

export function convertPipelineApiToNormalPipelines(
  pipelineApi: any[],
  referenceInchesPerPixels: number
) {
  return pipelineApi.map((pipe: any) => {
    const newID = generateId();

    pipe.properties.id = newID;
    const pipelineWidth =
      pipe.properties?.pipelineWidth * referenceInchesPerPixels * 2;
    const className = PIPELINE_WIDTHS_MAP[pipelineWidth];
    pipe.properties.className = className;
    pipe.properties.pipelineWidth = pipelineWidth;

    pipe.geometry.coordinates = pipe.geometry.coordinates.map(
      function fixHeightCoord(coordinates: any) {
        if (Array.isArray(coordinates?.[0])) {
          return fixHeightCoord(coordinates[0]);
        } else if (isNumber(coordinates?.[0])) {
          return [
            coordinates[0],
            coordinates[1],
            coordinates[2] ? -coordinates[2] : 0,
          ];
        } else {
          return;
        }
      }
    );

    return pipelineToPolygon(pipe, null, referenceInchesPerPixels);
  });
}

export function updatePipelineHeight(
  pipeline: any,
  distance: number,
  referenceInchesPerPixels: number,
  snapToGround = false,
  reverse = false
) {
  const snappedDistance =
    (reverse ? 1 : -1) *
    PIPELINE_LENGTHS.map((l) => l / referenceInchesPerPixels).reduce(
      (prev, curr) =>
        Math.abs(curr - distance) < Math.abs(prev - distance) ? curr : prev
    );

  return roundFeatureCoords({
    ...pipeline,
    geometry: {
      ...pipeline.geometry,
      coordinates: pipeline.geometry.coordinates.map((point: any) => [
        point[0],
        point[1],
        snapToGround ? 0 : (point[2] || 0) + snappedDistance,
      ]),
    },
  });
}

export function MultiLineSegmentsToLine(multiline: any) {
  const cleanedCoordinates: any[] = [];

  multiline.geometry.coordinates.forEach((segment: any) => {
    cleanedCoordinates.push(segment[0]);
  });

  return {
    ...multiline,
    geometry: {
      type: GEOJSON_TYPES.LineString,
      coordinates: cleanedCoordinates,
    },
  };
}
